.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: black;
}

.screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.content-box {
  padding: 20px 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 36px;
  font-weight: bold;
  color: #1a202c; /* Tailwind gray-900 */
}

.paragraph {
  margin-top: 12px;
  font-size: 18px;
  color: #718096; /* Tailwind gray-600 */
}

.button-container {
  margin-top: 24px;
}

.status-button {
  padding: 8px 16px;
  background-color: #4299e1; /* Tailwind blue-500 */
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.status-button:hover {
  background-color: #3182ce; /* Tailwind blue-600 */
}

.status-button:focus {
  outline: none;
}